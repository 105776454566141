<template>
  <div class="text-center">
    <h1>{{ $t('app_name') }}</h1>
    <v-btn
      block
      large
      to="/login"
    >
      {{ $t('views.home.go_to_login') }}
    </v-btn>
    <v-btn
      block
      large
      to="/register"
    >
      {{ $t('views.home.go_to_register') }}
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'StartPage',
};
</script>
